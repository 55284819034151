<script>
import debounce from "debounce";
import { mapActions, mapGetters } from "vuex";
import tnVue from "@/mixins/tn.vue";
import TableCog from "@/components/table-cog.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

const storeUniqKey = "show-pharmacy-requirements-";
const wrapUniqKey = "show-pharmacy-requirements-wrap-";

export default {
  mixins: [tnVue, TableResizeVue, TableNavFunctionsVue],
  components: {
    TableCog,
  },

  props: {
    tableHeight: Number,
    productGuid: String,
    priceListLineItemGuid: String,
    basketPriceListProviderResultGuid: String,
    uniqKey: String,
    amount: Number,
    isShowSearchBtn: {
      type: Boolean,
      default: () => true,
    },
    isHideBtn: {
      type: Boolean,
      default: () => true,
    },
    customDivider: {
      type: String,
      default: 'mt-2',
    },
  },

  data: (vm) => ({
    ln: "pharmacy_requirements",
    sortBy: null,
    options: vm.$getSortLocal({ key: "pharmacy-requirements" }),
    isShowCog: true,
    isInit: false,
    isWrap: localStorage.getItem(wrapUniqKey + vm.uniqKey) != "false",
    isHidePharmacyRequirements: localStorage.getItem(storeUniqKey + vm.uniqKey) != "false",

    isShowAnalog: localStorage.getItem(vm.uniqKey + "-pharmacy-is-show-analog") == "true" ? true : null,
    isShowReplacement: localStorage.getItem(vm.uniqKey + "-pharmacy-is-show-replacement") == "true" ? true : null,
  }),

  computed: {
    ...mapGetters("pharmacy-requirements", {
      entries: "GET",
      entriesCount: "GET_COUNT",
      entriesTotals: "GET_TOTALS",
    }),

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          class: "disable-resize",
          _required: true,
          _resize: false,
        },
        {
          text: vm.tn("table.branch"),
          value: "branchName",
          _required: true,
          width: 140,
        },
        {
          text: vm.tn("table.linked_product_name"),
          value: "linkedProductName",
          _required: true,
          width: vm.$dxcs.productName,
        },
        {
          text: vm.tn("table.linked_manufacturer_name"),
          value: "manufacturerName",
          _required: true,
          width: vm.$dxcs.manufacturer,
        },
        {
          text: vm.tn("table.quantity_stock_need"),
          value: "quantityStockNeed",
          width: vm.$dxcs.qty,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.quantity_reject"),
          value: "quantityReject",
          width: vm.$dxcs.qty,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.quantity_stock"),
          value: "quantityStock",
          width: vm.$dxcs.qty,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.sale_price"),
          value: "salePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }
            if (!vm.$number(vm.amount)) return vm.$sum(val);

            const a = vm.$number(vm.amount);
            const b = item.salePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.tn("table.sale_last_date_time"),
          value: "saleLastDateTime",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.sale_quantity_day30"),
          value: "saleQuantityDay30",
          width: vm.$dxcs.qtyString,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.inbound_last_date_time"),
          value: "inboundLastDateTime",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.inbound_supplier_name"),
          value: "inboundSupplierName",
        },
        {
          text: vm.tn("table.inbound_last_quantity"),
          value: "inboundLastQuantity",
          width: vm.$dxcs.qtyString,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.inbound_last_price"),
          value: "inboundLastPrice",
          width: vm.$dxcs.price,
          _filter: (v) => vm.$sum(v),
          _align: "right",
          _resize: false,
        },
        {
          text: vm.tn("table.created_on"),
          value: "createdOn",
          width: 10,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.modified_at"),
          value: "modifiedOn",
          width: 10,
          _filter: (v) => vm.$date(v),
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),
  },

  created() {
    this.$emit("isHide", this.isHidePharmacyRequirements);
    this.$nextTick(() => (this.isInit = true));
  },

  watch: {
    tableHeaders() {
      this.isShowCog = false;
      this.$nextTick(() => (this.isShowCog = true));
    },
  },

  methods: {
    ...mapActions("pharmacy-requirements", {
      getEntriesApi: "GET_API",
    }),

    async getEntries(payload) {
      if (payload?.reset) this.options.page = 1;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.$setSortLocal({ key: "pharmacy-requirements", options: this.options });

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          sortOrders: this.$getSortOrders(this.options),
          isShowAnalog: this.isShowAnalog,
          isShowReplacement: this.isShowReplacement,
        },
      };

      if (this.productGuid)
        params.options.productGuid = this.productGuid
      if (this.priceListLineItemGuid)
        params.options.priceListLineItemGuid = this.priceListLineItemGuid
      if (this.basketPriceListProviderResultGuid)
        params.options.basketPriceListProviderResultGuid = this.basketPriceListProviderResultGuid

      if (this.searchText) params.options.search = this.searchText;
      if (this.isShowAnalog == null) delete params.options.isShowAnalog;
      if (this.isShowReplacement == null) delete params.options.isShowReplacement;
      if (!params.options.sortBy) delete params.options.sortDirection;

      await this.getEntriesApi(params);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onKeyup(e) {
      if (e.keyCode == 115) {
        this.$refs.searchBox?.focus();
        this.$nextTick(() => {
          const input = this.$refs.searchBox?.$el?.querySelector("input");
          input?.select();
        });
      }
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onClear() {
      this.searchText = null;
      this.$nextTick(() => this.getEntries());
    },

    onWrap() {
      this.isWrap = !this.isWrap;
      const isWrap = this.isWrap;
      localStorage.setItem(wrapUniqKey + this.uniqKey, isWrap);
    },

    onHidePharmacyRequirements() {
      this.isHidePharmacyRequirements = !this.isHidePharmacyRequirements;
      const isHide = this.isHidePharmacyRequirements;
      this.$emit("isHide", isHide);
      localStorage.setItem(storeUniqKey + this.uniqKey, isHide);
    },

    onShowAnalog() {
      this.isShowAnalog = !this.isShowAnalog;
      const isShow = this.isShowAnalog;
      localStorage.setItem(this.uniqKey + "-pharmacy-is-show-analog", isShow);
      this.$nextTick(() => this.getEntries({ reset: true }));
    },

    onShowReplacement() {
      this.isShowReplacement = !this.isShowReplacement;
      const isShow = this.isShowReplacement;
      localStorage.setItem(this.uniqKey + "-pharmacy-is-show-replacement", isShow);
      this.$nextTick(() => this.getEntries({ reset: true }));
    },

    getRowStyle(item) {
      if (item.productState == 2) return { backgroundColor: "#cdfad4" };
      else if (item.productState == 3) return { backgroundColor: "#f5facd" };
      else return { backgroundColor: "#fff" };
    },
  },
};
</script>

<template>
  <div v-if="isInit">
    <div class="d-flex align-center pt-2">
      <v-btn
        v-if="isHideBtn"
        outlined
        depressed
        height="40"
        min-height="40"
        color="primary"
        class="btn-icon-right"
        @click.stop="onHidePharmacyRequirements"
      >
        {{ tn("details_btn") }}
        <div class="pl-1" />
        <v-icon v-if="isHidePharmacyRequirements"> mdi-chevron-down </v-icon>
        <v-icon v-else> mdi-chevron-right </v-icon>
      </v-btn>

      <h3 v-else> {{ tn("details_btn") }} </h3>

      <template
        v-if="isHidePharmacyRequirements && productGuid || priceListLineItemGuid || basketPriceListProviderResultGuid"
      >
        <div class="mx-1" />

        <slot name="actions" />
        <v-spacer />
        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          @click="getEntries"
          :disabled="isLoading"
          :title="tn('refresh_btn')"
        >
          <v-icon> mdi-sync </v-icon>
        </v-btn>

        <div class="mx-1" />

        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          elevation="0"
          @click="onWrap"
          :disabled="isLoading"
          :loading="isLoading"
          :title="isWrap ? tn('wrap_btn') : tn('no_wrap_btn')"
          class="mr-2"
        >
          <v-icon v-if="!isWrap"> mdi-wrap </v-icon>
          <v-icon v-else> mdi-wrap-disabled </v-icon>
        </v-btn>

        <v-btn
          :color="isShowAnalog ? '#cdfad4' : 'primary'"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          :outlined="!isShowAnalog"
          elevation="0"
          @click="onShowAnalog"
          :disabled="isLoading"
          :loading="isLoading"
          :title="tn('show_analog_btn')"
          class="mr-2"
        >
          <v-icon :color="isShowAnalog ? 'primary' : ''"> А </v-icon>
        </v-btn>

        <v-btn
          :color="isShowReplacement ? '#f5facd' : 'primary'"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          :outlined="!isShowReplacement"
          elevation="0"
          @click="onShowReplacement"
          :disabled="isLoading"
          :loading="isLoading"
          :title="tn('show_replacement_btn')"
          class="mr-2"
        >
          <v-icon :color="isShowReplacement ? 'primary' : ''"> З </v-icon>
        </v-btn>

        <table-cog
          :headersData="tableHeaders"
          @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
          :cacheKey="uniqKey"
        />

        <div
          class="mx-1"
          v-if="isShowSearchBtn"
        />

        <v-text-field
          v-if="isShowSearchBtn"
          outlined
          hide-details
          dense
          append-icon="mdi-magnify"
          @click:append="onSearch"
          @keyup.enter="onSearch"
          @click:clear="onClear"
          style="max-width: 300px; width: 100%"
          :label="tn('search_F4')"
          v-model="searchText"
          clearable
          ref="searchBox"
        />
      </template>
    </div>

    <template
      v-if="isHidePharmacyRequirements && productGuid || priceListLineItemGuid || basketPriceListProviderResultGuid"
    >
      <div :class="customDivider" />

      <v-data-table
        v-if="filteredHeaders.length && tableHeight > 0"
        :headers="filteredHeaders"
        dense
        :height="tableHeight"
        :mobile-breakpoint="0"
        :loading="isLoading"
        :options.sync="options"
        :items="entries"
        :server-items-length="entriesCount"
        fixed-header
        :footer-props="{
          itemsPerPageOptions: [25, 50],
          showCurrentPage: true,
          showFirstLastPage: true,
          itemsPerPageText: $t('table.per_page_text'),
        }"
        v-resize-table="{ tableHeaders: filteredHeaders, callback: onUpdateTdSizes }"
      >
        <template v-slot:item="{ item, headers, index }">
          <tr
            class="cursor-pointer"
            @click="selected = { ...item }"
            :class="selected.guid == item.guid && 'tr-active'"
            :style="getRowStyle(item)"
          >
            <template v-for="head in headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-0 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
                />
              </td>

              <td
                v-else-if="head.value == 'branchName'"
                :key="head.value + 1"
                :class="isWrap ? 'text-nowrap' : 'text-wrap'"
                :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
              >
                <div class="d-flex align-center">
                  <div
                    v-if="item.color"
                    style="min-width: 14px; min-height: 14px; margin-right: 4px; border-radius: 50%"
                    :style="{ backgroundColor: item.color }"
                  />
                  <div
                    v-text="item.branchName"
                    :style="{ minWidth: head.minWidth }"
                  />
                </div>
              </td>

              <td
                v-else-if="head.value == 'linkedProductName'"
                :key="head.value + 2"
                class="px-0 text-center"
                :class="isWrap ? 'text-nowrap' : 'text-wrap'"
                :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-left px-1"
                      v-bind="attrs"
                      v-on="on"
                      v-text="item.linkedProductName"
                    />
                  </template>
                  <span v-if="item.productState == 1">{{ tn("original") }}</span>
                  <span v-else-if="item.productState == 2">{{ tn("analog") }}</span>
                  <span v-else-if="item.productState == 3">{{ tn("replacement") }}</span>
                </v-tooltip>
              </td>

              <td
                v-else-if="head.value == 'manufacturerName'"
                :key="head.value + 3"
                :class="isWrap ? 'text-nowrap' : 'text-wrap'"
                :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
              >
                <div
                  v-text="item.manufacturerName"
                  :style="{ minWidth: head.minWidth }"
                />
              </td>

              <td
                v-else
                :key="head.value + 4"
                :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
              >
                <div
                  :style="{ minWidth: head.minWidth, textAlign: head._align, ...head._getStyle?.(item[head.value], item) }"
                  v-html="head._filter ? head._filter(item[head.value], item) : item[head.value]"
                  :class="{ 'text-nowrap': head._wrap }"
                />
              </td>
            </template>
          </tr>
        </template>

        <template
          v-if="entriesCount > 1"
          #body.append="bodyProps"
        >
          <tr class="sticky-table-footer">
            <td
              v-for="(header, i) in bodyProps.headers"
              :key="i"
            >
              <div
                v-if="header.value == 'number'"
                class="text-nowrap text-center"
              >
                {{ entriesCount | sum }}
              </div>

              <div
                v-else-if="header.value == 'inboundLastQuantity'"
                class="text-nowrap text-right"
              >
                {{ entriesTotals.totalInboundLastQuantity | sum }}
              </div>

              <div
                v-else-if="header.value == 'quantityReject'"
                class="text-nowrap text-right"
              >
                {{ entriesTotals.totalQuantityReject | sum }}
              </div>

              <div
                v-else-if="header.value == 'quantityStock'"
                class="text-nowrap text-right"
              >
                {{ entriesTotals.totalQuantityStock | sum }}
              </div>

              <div
                v-else-if="header.value == 'quantityStockNeed'"
                class="text-nowrap text-right"
              >
                {{ entriesTotals.totalQuantityStockNeed | sum }}
              </div>

              <div
                v-else-if="header.value == 'saleQuantityDay30'"
                class="text-nowrap text-right"
              >
                {{ entriesTotals.totalSaleQuantityDay30 | sum }}
              </div>

              <div v-else />
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </div>
</template>
